// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Turbolinks from "turbolinks"
Turbolinks.start()

import $ from 'jquery'
window.jQuery = window.$ = $

import wNumb from "wnumb";
window.wNumb = wNumb;

import Chart from "chart.js";
window.Chart = Chart;

import noUiSlider from 'nouislider';
window.noUiSlider = noUiSlider;

import lity from 'lity';

import "./application.scss"

document.addEventListener("turbolinks:load", function() {
  $(document).on('click', '[data-lightbox]', lity);
})

document.addEventListener('ajax:success', function(event) {
  var detail = event.detail;
  var data = detail[0], status = detail[1], xhr = detail[2];
  if (data.response) {
    let response  = data.response;
    if (response.current_substep == 1) {
      $('#survey_content').html(data.html);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      $('#step_content').html(data.html);
      document.getElementById("step_content").scrollIntoView();
      window.scrollBy(0, -60);
    }
  } else {
    $('#share_with_friend').addClass('hidden');
    $('#share_thanks').removeClass('hidden');
  }
});

$("#contact_form").submit(function(e) {

  e.preventDefault(); // avoid to execute the actual submit of the form.

  var form = $(this);
  var actionUrl = form.attr('action')

  $.ajax({
      type: "POST",
      url: actionUrl,
      data: form.serialize(), // serializes the form's elements.
      success: function(data)
      {
        $('#contact-form-errors').fadeOut();
        $('#contact-form-success').fadeIn()
        $('#contact_form').fadeOut()
      },
      error: function (data) {
        if (data.responseJSON.length > 0) {
          let errors = "<ul class='text-sm list-disc text-red-600'>"
          data.responseJSON.forEach((error) => {
            errors = errors + "<li>" + error + "</li>"
          })
          errors = errors + "</ol>"
          $('#error-details').html(errors)
        }

        $('#contact-form-errors').fadeIn();
      }
  });

});

